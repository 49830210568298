// @ts-strict-ignore
import React from "react"

import { Form, Formik, useField } from "formik"
import * as Yup from "yup"

import { Alert, Button, Stack } from "@kiwicom/orbit-components"
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox"
import Text from "@kiwicom/orbit-components/lib/Text"
import AlertIcon from "@kiwicom/orbit-components/lib/icons/Alert"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"

interface FormikCheckboxProps {
  name: string
  label: string
  dataTest?: string
  tooltip?: React.ReactElement
}

export const FormikCheckbox = ({
  name,
  dataTest,
  label,
  tooltip,
}: FormikCheckboxProps) => {
  const [field] = useField({ name: name, type: "checkbox" })
  return (
    <Checkbox
      checked={field.checked}
      tooltip={tooltip}
      name={name}
      dataTest={dataTest}
      label={label}
      {...field}
    />
  )
}

type SubmitStatus = {
  success: boolean
  error: string | null
}
type SubmitHooks = {
  setSubmitting: (isSubmitting: boolean) => void
  setStatus: (status: SubmitStatus) => void
}
type onSubmitFunction<T> = (values: T, hooks: SubmitHooks) => void

interface EmberFormModalArgs<V> {
  handleClose: (event: any) => void
  title: string
  description?: string
  initialFormValues: V
  validationSchema
  onSubmit: onSubmitFunction<V>
  children: React.ReactNode
  errorTitle?: string
  errorAdditionalText?: string
  dataTestPrefix?: string
}

export const EmberFormModal = <V,>({
  handleClose,
  title,
  description,
  initialFormValues,
  validationSchema,
  onSubmit,
  children,
  errorTitle = "There was an error submitting the form",
  errorAdditionalText = ". Refresh the page and try again, or contact tech if it's still not working.",
  dataTestPrefix = "ember-form",
}: EmberFormModalArgs<V>): JSX.Element => {
  return (
    <EmberModal
      hasCloseButton
      onClose={handleClose}
      dataTest={`${dataTestPrefix}-modal`}
    >
      <EmberModalHeader
        title={title}
        description={description}
        dataTest={`${dataTestPrefix}-header`}
      />
      <EmberModalSection>
        <Formik
          initialValues={initialFormValues}
          validationSchema={Yup.object(validationSchema)}
          initialStatus={{ success: false, error: null }}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form>
              <Stack spacing="large" dataTest={`${dataTestPrefix}-form-stack`}>
                {children}
                <Button
                  dataTest={`${dataTestPrefix}-submit`}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  submit={true}
                  fullWidth={true}
                >
                  Save
                </Button>
                {status?.error && (
                  <Alert
                    type="critical"
                    title={errorTitle}
                    icon={<AlertIcon />}
                    dataTest={`${dataTestPrefix}-error-text`}
                  >
                    <Text>
                      {status.error}
                      {errorAdditionalText}
                    </Text>
                  </Alert>
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}

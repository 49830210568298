import React, { useState } from "react"

import { Badge, Stack, Text, TextLink } from "@kiwicom/orbit-components"

import { EmberInfoBox, InfoRow } from "components/generic/ember-card"

import {
  DriverPreference,
  Eligibility,
  RouteEligibility,
  VehicleEligibility,
} from "types/person"

import {
  formatAsAbbreviatedDate,
  formatAsMinimizedDate,
  getDefaultTimezone,
} from "utils/date-utils"
import { capitalizeFirstLetter } from "utils/string-utils"

import ChangePreferenceModal from "./change-preference-modal"
import RouteEligibilityModal from "./route-eligibility-modal"
import VehicleEligibilityModal from "./vehicle-eligibility-modal"

interface HRInformationCardProps {
  driverUid: string
  driverName: string
  preferences: DriverPreference[]
  routes: RouteEligibility[]
  vehicles: VehicleEligibility[]
  refreshOnEdit: () => void
}

// Given a list of eligibilities, split them into active and historic
function splitEligibilities<T extends Eligibility>(eligibilities: T[]) {
  const now = new Date()

  const active: T[] = []
  const historic: T[] = []

  eligibilities?.forEach((route) => {
    if (route.validUntil && new Date(route.validUntil) < now) {
      historic.push(route)
    } else {
      active.push(route)
    }
  })

  return [active, historic]
}

interface EligibilityBadgesProps<T extends Eligibility> {
  active: T[]
  describe: (eligibility: T) => string
}

function EligibilityBadges<T extends Eligibility>({
  active,
  describe,
}: EligibilityBadgesProps<T>) {
  const now = new Date()

  return active.length > 0 ? (
    <>
      {active.map((eligibility, index) => {
        const fromString =
          new Date(eligibility.validFrom) > now
            ? " from " +
              formatAsMinimizedDate(eligibility.validFrom, getDefaultTimezone())
            : ""
        const untilString = eligibility.validUntil
          ? " until " +
            formatAsMinimizedDate(eligibility.validUntil, getDefaultTimezone())
          : ""
        const string = `${describe(eligibility)}${fromString}${untilString}`
        return (
          <Badge type="successSubtle" key={index}>
            {string}
          </Badge>
        )
      })}
    </>
  ) : (
    <Text>None</Text>
  )
}

const HRInformationCard = ({
  driverUid,
  driverName,
  preferences,
  routes,
  vehicles,
  refreshOnEdit,
}: HRInformationCardProps) => {
  const [showChangePreferenceModal, setShowChangePreferenceModal] =
    useState(false)
  const [showRouteEligibilityModal, setShowRouteEligibilityModal] =
    useState(false)
  const [showVehicleEligibilityModal, setShowVehicleEligibilityModal] =
    useState(false)

  const now = new Date()

  const [activeRoutes, historicRoutes] = splitEligibilities(routes)
  const [activeVehicles, historicVehicles] = splitEligibilities(vehicles)

  const sortedPreferences = preferences.sort((a, b) =>
    a.submittedAt > b.submittedAt ? -1 : 1
  )
  const currentPreference = sortedPreferences.find(
    (preference) => new Date(preference.asOf) < now
  )
  const laterPreferences = sortedPreferences
    .filter((preference) =>
      currentPreference
        ? preference.submittedAt > currentPreference.submittedAt
        : true
    )
    .sort((a, b) => (a.asOf > b.asOf ? 1 : -1))
  const nextPreference =
    laterPreferences.length > 0 ? laterPreferences[0] : null
  const preferenceString = `${
    currentPreference
      ? capitalizeFirstLetter(currentPreference.preference)
      : "None"
  }${
    nextPreference
      ? " until " +
        formatAsAbbreviatedDate(nextPreference.asOf, getDefaultTimezone())
      : ""
  }`

  return (
    <>
      {showRouteEligibilityModal && (
        <RouteEligibilityModal
          driverUid={driverUid}
          activeRoutes={activeRoutes}
          historicRoutes={historicRoutes}
          handleClose={() => setShowRouteEligibilityModal(false)}
          refreshOnEdit={refreshOnEdit}
        />
      )}
      {showVehicleEligibilityModal && (
        <VehicleEligibilityModal
          driverUid={driverUid}
          driverName={driverName}
          activeVehicles={activeVehicles}
          historicVehicles={historicVehicles}
          handleClose={() => setShowVehicleEligibilityModal(false)}
          refreshOnEdit={refreshOnEdit}
        />
      )}
      {showChangePreferenceModal && (
        <ChangePreferenceModal
          driverUid={driverUid}
          preferences={sortedPreferences}
          handleClose={() => setShowChangePreferenceModal(false)}
          refreshOnEdit={refreshOnEdit}
        />
      )}
      <EmberInfoBox
        sectionTitle="Routes and Preferences"
        columnGap="20px"
        rowGap="12px"
      >
        <InfoRow label="Allowed Routes">
          <Stack direction="row" wrap={true}>
            <EligibilityBadges
              active={activeRoutes}
              describe={(r) => r.routeNumber}
            />
            <TextLink
              type="secondary"
              onClick={() => setShowRouteEligibilityModal(true)}
              dataTest="edit-route-eligibility-modal"
            >
              Edit
            </TextLink>
          </Stack>
        </InfoRow>

        <InfoRow label="Allowed Vehicles">
          <Stack direction="row" wrap={true}>
            <EligibilityBadges
              active={activeVehicles}
              describe={(r) => r.vehicleModel}
            />
            <TextLink
              type="secondary"
              onClick={() => setShowVehicleEligibilityModal(true)}
              dataTest="edit-vehicle-eligibility-modal"
            >
              Edit
            </TextLink>
          </Stack>
        </InfoRow>

        <InfoRow label="Preferred Shifts">
          <Stack direction="row">
            <Text>{preferenceString}</Text>
            <TextLink
              type="secondary"
              onClick={() => setShowChangePreferenceModal(true)}
              dataTest="open-preference-modal"
            >
              Edit
            </TextLink>
          </Stack>
        </InfoRow>
      </EmberInfoBox>
    </>
  )
}

export default HRInformationCard

import { parseErrorMessage } from "api/errors"

import { Unsubscribable } from "types/observable"
import { OffPattern } from "types/offPattern"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"

export interface fetchOffPatternsParams {
  employeeUid: string
  onSuccess: (offPatterns: OffPattern[]) => void
  onError: (errorMessage: string) => void
}

export function fetchOffPatterns({
  employeeUid,
  onSuccess,
  onError,
}: fetchOffPatternsParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/staff/rota/off-patterns/${getQueryString({
      employeeUid: employeeUid,
    })}`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseErrorMessage(response))
    }
  })
}

export interface inspectOffPatternParams {
  requestBody: any
  onSuccess: (events) => void
  onError: (errorMessage: string) => void
}

export function inspectOffPattern({
  requestBody,
  onSuccess,
  onError,
}: inspectOffPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/staff/rota/off-patterns/inspect/",
    method: "POST",
    body: requestBody,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseErrorMessage(response))
    }
  })
}

export interface createOrUpdateOffPatternParams {
  requestBody: any
  editOffPatternId?: number
  onSuccess: () => void
  onError: (errorMessage: string) => void
}

export function createOrUpdateOffPattern({
  requestBody,
  editOffPatternId,
  onSuccess,
  onError,
}: createOrUpdateOffPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/staff/rota/off-patterns/${
      editOffPatternId ? editOffPatternId + "/" : ""
    }`,
    method: editOffPatternId ? "PUT" : "POST",
    body: requestBody,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseErrorMessage(response))
    }
  })
}

export interface deleteOffPatternParams {
  offPatternId: number
  onSuccess: () => void
  onError: (errorMessage: string) => void
}

export function deleteOffPattern({
  offPatternId,
  onSuccess,
  onError,
}: deleteOffPatternParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/staff/rota/off-patterns/${offPatternId}/`,
    method: "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseErrorMessage(response))
    }
  })
}

import { Conversation } from "./chat"
import { Suspension } from "./suspension"

export enum LocationType {
  STOP_POINT = "STOP_POINT",
  STOP_AREA = "STOP_AREA",
  STATION = "STATION",
  HUB = "HUB",
  DEPOT = "DEPOT",
  CITY = "CITY",
  COUNTRY = "COUNTRY",
}

export interface FlatStopReplacement {
  description: string
  isCancelled: boolean
  originalLocationId: number
  replacementLocationId: number
  useStopName: boolean
}

export interface Route {
  id: number
  number: string
  direction: string
  description: string
  inactiveFrom: string | null
}

export interface StopReplacementPattern {
  uid: string
  isCancelled: boolean
  originalLocationId: number
  replacementLocationId: number
  useStopName: boolean
  startTime: string
  endTime: string
  startTimeWithinDay: string
  endTimeWithinDay: string
  daysOfWeek: number[]
  routes: Route[]
}

export interface SuspensionPattern {
  uid: string
  suspendedLocationId: number
  startTime: string
  endTime: string
  startTimeWithinDay: string
  endTimeWithinDay: string
  daysOfWeek: number[]
  routes: Route[]
}

export interface Disruption {
  uid: string
  title: string
  description: string
  cause: string
  startTime: string
  endTime: string
  latitude: number
  longitude: number
  patterns: StopReplacementPattern[] | undefined
  suspensionPatterns: SuspensionPattern[] | undefined
  conversation: Conversation | undefined
}

export interface StopReplacement {
  id: number
  closure: Disruption
  isCancelled: boolean
  originalLocationId: number
  replacementLocationId: number
  useStopName: boolean
}

export interface Location {
  id: number
  type: LocationType
  name: string
  regionName: string
  code?: string
  codeDetail?: string
  detailedName: string
  localName?: string
  description?: string
  lon?: number
  lat?: number
  googlePlaceId?: string
  atcoCode?: string
  bookableFrom?: string
  bookableUntil?: string
  allowedOrigins?: number[]
  allowedDestinations?: number[]
  areaId?: number
  timezone: string
  zone?: { latitude: number; longitude: number }[]
  heading?: number
  stopReplacement?: FlatStopReplacement
}

// Used in some locations (e.g. quote) in place of normal location time model
// to support legs where frequent transit may not have location time
export interface FlatLocationTime extends Location {
  locationTimeId?: number
  bookingCutOffMins: number
  preBookedOnly: boolean
  skipped: boolean
  suspension?: Suspension
}

// @ts-strict-ignore
import React, { useState } from "react"

import { Grid, Stack, TextLink } from "@kiwicom/orbit-components"

import { InfoRow } from "components/generic/ember-card"
import { EmptyStateCardSection } from "components/generic/empty-state-card-section"

import { DriverContract } from "types/person"

import { formatAsAbbreviatedDate, getDefaultTimezone } from "utils/date-utils"

import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "../../generic/ember-card"
import { ContractModal } from "./driver-contract-modal"

interface HRInformationCardProps {
  driverUid: string
  contracts: DriverContract[]
  refreshOnEdit: () => void
}

const DriverContractCard = ({
  driverUid,
  contracts,
  refreshOnEdit,
}: HRInformationCardProps) => {
  const [showContractModal, setShowContractModal] = useState(false)
  const lastContract =
    contracts.length > 0 ? contracts[contracts.length - 1] : null
  const firstContract = contracts.length > 0 ? contracts[0] : null
  const now = new Date()
  const currentContract = contracts.find(
    (contract) =>
      new Date(contract.validFrom) < now &&
      (!contract.validUntil || new Date(contract.validUntil) > now)
  )
  const nextContract =
    currentContract &&
    currentContract.id !== lastContract.id &&
    contracts.find((contract) => contract.validFrom > currentContract.validFrom)

  const untilString =
    currentContract &&
    currentContract.validUntil &&
    ` until ${formatAsAbbreviatedDate(
      currentContract.validUntil,
      getDefaultTimezone()
    )}`

  const addUntilString = (value: string) => value + untilString

  let contractTypeTitle = "None"
  let hoursTitle = "None"
  let hubTitle = "None"

  if (currentContract) {
    contractTypeTitle = currentContract.type
    hoursTitle = currentContract.hoursPerWeek.toString()
    hubTitle = currentContract.hub.name

    if (nextContract) {
      if (nextContract.type !== currentContract.type) {
        contractTypeTitle = addUntilString(contractTypeTitle)
      }
      if (nextContract.hoursPerWeek !== currentContract.hoursPerWeek) {
        hoursTitle = addUntilString(hoursTitle)
      }
      if (nextContract.hub.id !== currentContract.hub.id) {
        hubTitle = addUntilString(hubTitle)
      }
    }
  }

  return (
    <>
      {showContractModal && (
        <ContractModal
          contracts={contracts}
          driverUid={driverUid}
          lastContract={lastContract}
          handleClose={() => setShowContractModal(false)}
          refreshOnEdit={refreshOnEdit}
        />
      )}
      <EmberCardGroup
        sectionTitle="Contract"
        actions={[
          contracts.length > 0 && (
            <TextLink
              onClick={() => setShowContractModal(true)}
              type="secondary"
              key="edit-contract"
              dataTest="edit-contract"
            >
              Edit
            </TextLink>
          ),
        ]}
      >
        <EmberCard>
          {contracts.length > 0 ? (
            <EmberCardSection>
              <Stack spacing="small">
                <Grid columns="max-content 1fr" columnGap="20px" rowGap="12px">
                  <InfoRow label="Hours">{hoursTitle}</InfoRow>
                  <InfoRow
                    label="Employed Since"
                    tooltip="When the person first worked for Ember. There may have been interruptions or changes to their employment since this date."
                  >
                    <Stack direction="row">
                      {formatAsAbbreviatedDate(
                        firstContract.validFrom,
                        getDefaultTimezone()
                      )}
                    </Stack>
                  </InfoRow>
                  {lastContract.validUntil && (
                    <InfoRow label="End">
                      <Stack direction="row">
                        {formatAsAbbreviatedDate(
                          lastContract.validUntil,
                          getDefaultTimezone()
                        )}
                      </Stack>
                    </InfoRow>
                  )}
                  <InfoRow label="Type">{contractTypeTitle}</InfoRow>
                  <InfoRow label="Hub">{hubTitle}</InfoRow>
                </Grid>
              </Stack>
            </EmberCardSection>
          ) : (
            <EmptyStateCardSection
              title="No contract in place with this staff member"
              description="Add the contract details as soon as they've been agreed"
              actionLabel="Add Contract"
              actionOnclick={() => setShowContractModal(true)}
              omitIllustration
            />
          )}
        </EmberCard>
      </EmberCardGroup>
    </>
  )
}

export default DriverContractCard

// @ts-strict-ignore
import React, { useState } from "react"

import { addDays, endOfDay, format, parseISO, startOfDay } from "date-fns"
import { Form, Formik } from "formik"

import {
  Alert,
  Button,
  List,
  ListItem,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import { Alert as AlertIcon } from "@kiwicom/orbit-components/icons"

import {
  createOrUpdateOffPattern,
  deleteOffPattern,
  inspectOffPattern,
} from "api/off-patterns"

import { DateRange } from "components/generic/date-time/ember-date-range-picker"
import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { FormikCheckbox } from "components/generic/ember-modal/form-modal"
import {
  EmberDateInput,
  EmberDateRangeInput,
  EmberFormSelect,
  EmberTextInput,
} from "components/generic/formik-inputs"

import Yup from "logic/validation/yup-extended"

import { OffPattern } from "types/offPattern"

import {
  formatAsShortDateTimeRangeWithDayName,
  getDefaultTimezone,
} from "utils/date-utils"
import { useRotaVisibilityDate } from "utils/rota-visibility-utils"

const frequencyOptions = [
  {
    id: "1",
    title: "Every Week",
  },
  {
    id: "2",
    title: "Every Other Week",
  },
  {
    id: "3",
    title: "Every Third Week",
  },
  {
    id: "4",
    title: "Every Fourth Week",
  },
]

const getFrequency = (id: number) => {
  return frequencyOptions.find((option) => option.id === id.toString())
}

const endDayOptions = [
  {
    id: "0",
    title: "Monday",
  },
  {
    id: "1",
    title: "Tuesday",
  },
  {
    id: "2",
    title: "Wednesday",
  },
  {
    id: "3",
    title: "Thursday",
  },
  {
    id: "4",
    title: "Friday",
  },
  {
    id: "5",
    title: "Saturday",
  },
  {
    id: "6",
    title: "Sunday",
  },
]

const startDayOptions = [
  {
    id: "everyDay",
    title: "Every Day",
  },
  ...endDayOptions,
]

const getStartDay = (dayNumber?: number) => {
  if (dayNumber === null) {
    return startDayOptions[0]
  }
  return startDayOptions.find((option) => option.id === dayNumber.toString())
}

const getEndDay = (numberOfDays: number, startDayNumber?: number) => {
  if (startDayNumber === null) {
    return null
  }
  const endDayNumber = (startDayNumber + numberOfDays) % 7
  return endDayOptions.find((option) => option.id === endDayNumber.toString())
}

const interval = 15
const timeOptions = []
for (let i = 0; i < 24 * 60; i += interval) {
  const hours = Math.floor(i / 60)
  const minutes = i % 60
  const date = new Date(0, 0, 0, hours, minutes)
  const title = format(date, "HH:mm")
  const formatted = format(date, "HH:mm:ss")
  timeOptions.push({
    id: formatted,
    title: title,
  })
}

const getTime = (time: string) => {
  return {
    id: time,
    title: time,
  }
}

const getNumberOfDays = (
  startTime: string,
  endTime: string,
  startDay?: number,
  endDay?: number
) => {
  if (startDay === null) {
    // If the start day is null, the pattern is every day.
    // In that case, the number of days is either 1 or 0.
    // When the end time is before the start time, it means the pattern goes into the next day, so the number of days is 1.
    return endTime <= startTime ? 1 : 0
  } else {
    return (endDay - startDay + 7) % 7
  }
}

const getValidityDates = (
  openEndedPattern: boolean,
  dateRange: DateRange,
  startDate: Date
) => {
  if (openEndedPattern) {
    return {
      validityStart: startOfDay(startDate).toISOString(),
      validityEnd: null,
    }
  } else {
    return {
      validityStart: startOfDay(dateRange.start).toISOString(),
      validityEnd: endOfDay(dateRange.end).toISOString(),
    }
  }
}

const getRequestBody = (values: any) => {
  const {
    employeeUid,
    description,
    isCommitted,
    frequency,
    startDay,
    startTime,
    endDay,
    endTime,
    openEndedPattern,
    dateRange,
    startDate,
  } = values
  const startDayNumber =
    startDay.id === "everyDay" ? null : parseInt(startDay.id)
  const { validityStart, validityEnd } = getValidityDates(
    openEndedPattern,
    dateRange,
    startDate
  )
  return {
    employeeUid,
    description,
    isCommitted,
    frequency: parseInt(frequency.id),
    startDay: startDayNumber,
    startTimeOfDay: startTime.id,
    numberOfDays: getNumberOfDays(
      startTime.id,
      endTime.id,
      startDayNumber,
      endDay ? parseInt(endDay.id) : null
    ),
    endTimeOfDay: endTime.id,
    validityStart,
    validityEnd,
  }
}

const PatternEvents = ({ events, shouldShowInspectionResult }) => {
  if (!shouldShowInspectionResult) return null
  return (
    <Stack spacing="XSmall" dataTest="off-pattern-inspection-result">
      <Text size="large" weight="medium">
        {events.length == 0
          ? "No events will be generated from this pattern, you might want to change the time range or other parameters."
          : "Examples:"}
      </Text>
      <List>
        {events.map((event, i) => (
          <ListItem key={i}>
            {formatAsShortDateTimeRangeWithDayName(
              event.start,
              event.end,
              getDefaultTimezone()
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

interface OffPatternFormProps {
  submissionSuccess: boolean
  isSubmitting: boolean
  error: string
  isEveryDayPattern: boolean
  openEndedPattern: boolean
  values: any
  isEditing: boolean
  showDeleteForm: boolean
  setShowDeleteForm: () => void
  handlePatternDelete: () => void
}

const OffPatternForm = ({
  submissionSuccess,
  isSubmitting,
  error,
  isEveryDayPattern,
  openEndedPattern,
  values,
  isEditing,
  showDeleteForm,
  setShowDeleteForm,
  handlePatternDelete,
}: OffPatternFormProps) => {
  const [loadingInspection, setLoadingInspection] = useState(false)
  const [shouldShowInspectionResult, setShouldShowInspectionResult] =
    useState(false)
  const [inspectionEvents, setInspectionEvents] = useState([])
  const [inspectionError, setInspectionError] = useState("")

  const inspectionRequestOnSuccess = (response) => {
    setInspectionEvents(response.slice(0, 14))
    setLoadingInspection(false)
    setShouldShowInspectionResult(true)
  }

  const inspectionRequestOnError = (errorMessage) => {
    setInspectionError(errorMessage)
    setLoadingInspection(false)
  }

  const handleInspectionRequest = () => {
    setShouldShowInspectionResult(false)
    setInspectionEvents([])
    inspectOffPattern({
      requestBody: getRequestBody(values),
      onSuccess: inspectionRequestOnSuccess,
      onError: inspectionRequestOnError,
    })
  }

  return submissionSuccess ? (
    <Alert type="success">Success</Alert>
  ) : showDeleteForm ? (
    <Button
      disabled={isSubmitting}
      loading={isSubmitting}
      fullWidth={true}
      submit={true}
      type="critical"
      onClick={handlePatternDelete}
      dataTest="off-pattern-delete-button"
    >
      Delete Pattern
    </Button>
  ) : (
    <Form>
      <Stack spacing="large">
        <Stack spacing="XXSmall">
          <EmberTextInput
            label="Description"
            name="description"
            dataTest="off-pattern-modal-description"
          />
          <Text type="secondary">
            Include the reason and the times. Examples: "Weekends off on odd
            week numbers for child care" or "Sundays cannot start before 8am due
            to medical reasons".
          </Text>
        </Stack>
        <Stack spacing="XXSmall">
          <FormikCheckbox
            name="isCommitted"
            label="Contractual Requirement"
            dataTest="off-pattern-modal-is-committed"
          />
          <Text type="secondary">
            Only tick this option if this driver's contract prevents them from
            working during these times. Otherwise, leave this unticked.{" "}
          </Text>
        </Stack>

        <EmberFormSelect
          options={frequencyOptions}
          name="frequency"
          label="Frequency"
          dataTest="off-pattern-modal-frequency"
        />
        <Stack spacing="XXSmall" direction="row">
          <EmberFormSelect
            options={startDayOptions}
            name="startDay"
            label="Start Day"
            dataTest="off-pattern-modal-start-day"
          />
          <EmberFormSelect
            options={timeOptions}
            name="startTime"
            label="Start Time"
            dataTest="off-pattern-modal-start-time"
          />
        </Stack>

        <Stack spacing="XXSmall" direction="row">
          {!isEveryDayPattern && (
            <EmberFormSelect
              options={endDayOptions}
              name="endDay"
              label="End Day"
              dataTest="off-pattern-modal-end-day"
            />
          )}
          <EmberFormSelect
            options={timeOptions}
            name="endTime"
            label="End Time"
            dataTest="off-pattern-modal-end-time"
          />
        </Stack>
        <FormikCheckbox
          name="openEndedPattern"
          label="No end date"
          dataTest="off-pattern-modal-open-ended-checkbox"
        />
        {!openEndedPattern && (
          <EmberDateRangeInput
            label="Apply to Dates (inclusive)"
            name="dateRange"
            timezone={getDefaultTimezone()}
            dataTest="off-pattern-modal-date-range"
          />
        )}
        {openEndedPattern && (
          <EmberDateInput
            label="Start Date"
            name="startDate"
            dataTest="off-pattern-modal-start-date"
          />
        )}
        <PatternEvents
          events={inspectionEvents}
          shouldShowInspectionResult={shouldShowInspectionResult}
        />
        {isEditing && (
          <Stack spacing="small">
            <Text>
              If this pattern was created by mistake, you may wish to{" "}
              <TextLink
                type="secondary"
                onClick={setShowDeleteForm}
                dataTest="off-pattern-show-delete-form"
              >
                delete it
              </TextLink>{" "}
              instead.
            </Text>
          </Stack>
        )}
        <Stack spacing="small">
          <Button
            disabled={isSubmitting}
            loading={loadingInspection}
            fullWidth={true}
            onClick={handleInspectionRequest}
            type="secondary"
            dataTest="off-pattern-inspect-button"
          >
            {"Inspect"}
          </Button>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            fullWidth={true}
            submit={true}
            dataTest="off-pattern-submit-button"
          >
            {"Submit"}
          </Button>
        </Stack>
        {error && (
          <Alert type="critical" icon={<AlertIcon />}>
            <Text>{error}</Text>
          </Alert>
        )}
        {inspectionError && (
          <Alert type="critical" icon={<AlertIcon />}>
            <Text>{inspectionError}</Text>
          </Alert>
        )}
      </Stack>
    </Form>
  )
}

const getDefaultTimeRange = (rotaVisibilityDate?, editOffPattern?) => {
  if (!rotaVisibilityDate && !editOffPattern) {
    return {
      start: new Date(),
      end: new Date(),
    }
  }
  if (editOffPattern) {
    return {
      start: parseISO(editOffPattern.validFrom),
      end: editOffPattern.validUntil
        ? parseISO(editOffPattern.validUntil)
        : new Date(),
    }
  } else {
    return {
      start: rotaVisibilityDate,
      end: addDays(rotaVisibilityDate, 7),
    }
  }
}

const getDefaultStartDate = (rotaVisibilityDate?, editOffPattern?) => {
  if (!rotaVisibilityDate && !editOffPattern) {
    return new Date()
  }
  if (editOffPattern) {
    return parseISO(editOffPattern.validFrom)
  } else {
    return rotaVisibilityDate
  }
}

interface OffPatternModalProps {
  employeeUid: string
  handleClose: () => void
  loadOffPatterns: () => void
  editOffPattern?: OffPattern
}

const OffPatternModal = ({
  employeeUid,
  handleClose,
  loadOffPatterns,
  editOffPattern,
}: OffPatternModalProps) => {
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const rotaVisibilityDate = useRotaVisibilityDate(false)
  return (
    <EmberModal onClose={handleClose}>
      <EmberModalHeader
        dataTest="off-pattern-modal-header"
        title={showDeleteForm ? "Are you sure?" : "Off Pattern"}
      />
      <EmberModalSection>
        <Formik
          initialValues={{
            employeeUid: employeeUid,
            description: editOffPattern ? editOffPattern.description : "",
            isCommitted: editOffPattern ? editOffPattern.isCommitted : true,
            frequency: editOffPattern
              ? getFrequency(editOffPattern.frequency)
              : {
                  id: "1",
                  title: "Every Week",
                },

            startDay: editOffPattern
              ? getStartDay(editOffPattern.startDay)
              : {
                  id: "5",
                  title: "Saturday",
                },
            startTime: editOffPattern
              ? getTime(editOffPattern.startTimeOfDay)
              : "",
            endDay: editOffPattern
              ? getEndDay(editOffPattern.numberOfDays, editOffPattern.startDay)
              : {
                  id: "6",
                  title: "Sunday",
                },
            endTime: editOffPattern ? getTime(editOffPattern.endTimeOfDay) : "",
            openEndedPattern: editOffPattern
              ? !editOffPattern.validUntil
              : false,
            dateRange: getDefaultTimeRange(rotaVisibilityDate, editOffPattern),
            startDate: getDefaultStartDate(rotaVisibilityDate, editOffPattern),
            deleteItem: false,
          }}
          validationSchema={Yup.object({
            description: Yup.string().required("Enter a description"),
            startTime: Yup.object().required("Select a start time"),
            endTime: Yup.object().required("Select an end time"),
          })}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setSubmitting(true)
            setStatus(null)
            const onSuccess = () => {
              setStatus({
                success: true,
              })
              loadOffPatterns()
              handleClose()
              setSubmitting(false)
            }
            const onError = (errorMessage) => {
              setStatus({
                error: true,
                message: errorMessage,
              })
              setSubmitting(false)
            }
            if (values.deleteItem) {
              deleteOffPattern({
                offPatternId: editOffPattern.id,
                onSuccess,
                onError,
              })
            } else {
              createOrUpdateOffPattern({
                requestBody: getRequestBody(values),
                editOffPatternId: editOffPattern ? editOffPattern.id : null,
                onSuccess,
                onError,
              })
            }
          }}
        >
          {(props) => {
            return (
              <OffPatternForm
                submissionSuccess={props.status?.success}
                isSubmitting={props.isSubmitting}
                error={props.status?.error ? props.status.message : ""}
                isEveryDayPattern={props.values.startDay?.id === "everyDay"}
                openEndedPattern={props.values.openEndedPattern}
                values={props.values}
                isEditing={editOffPattern != null}
                showDeleteForm={showDeleteForm}
                setShowDeleteForm={() => setShowDeleteForm(true)}
                handlePatternDelete={() => {
                  props.setFieldValue("deleteItem", true)
                  props.handleSubmit()
                }}
              />
            )
          }}
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}

export default OffPatternModal

// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { addDays, startOfTomorrow } from "date-fns"
import { Form, Formik } from "formik"

import {
  Alert,
  Button,
  Loading,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"

import {
  addVehicleEligibility,
  deleteVehicleEligibility,
  patchVehicleEligibility,
} from "api/drivers"
import { EmberApiError } from "api/errors"
import { fetchVehicleModels } from "api/vehicles"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { FormikCheckbox } from "components/generic/ember-modal/form-modal"
import {
  EmberDateInput,
  EmberFormSelect,
} from "components/generic/formik-inputs"

import Yup from "logic/validation/yup-extended"

import { VehicleEligibility } from "types/person"
import { VehicleModel } from "types/vehicle"

import { zonedEndOfDay, zonedStartOfDay } from "utils/date-fns-utils"
import { formatAsMinimizedDate, getDefaultTimezone } from "utils/date-utils"

interface VehicleEligibilityModalProps {
  driverUid: string
  driverName: string
  activeVehicles: VehicleEligibility[]
  historicVehicles: VehicleEligibility[]
  refreshOnEdit: () => void
  handleClose: () => void
}

interface EligibilityTableProps {
  eligibilities: VehicleEligibility[]
  selectEligibility: (eligibility: VehicleEligibility) => void
  type: "Active" | "Historic"
}

const EligibilityTable = ({
  eligibilities,
  selectEligibility,
}: EligibilityTableProps) => {
  if (eligibilities.length == 0) {
    return (
      <Text>This driver is not currently eligibile to drive any vehicles.</Text>
    )
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Valid From</TableCell>
          <TableCell>Valid Until</TableCell>
          <TableCell>Vehicle Model</TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {eligibilities.map((eligibility, index) => (
          <TableRow key={index}>
            <TableCell>
              <Text>
                {formatAsMinimizedDate(
                  eligibility.validFrom,
                  getDefaultTimezone()
                )}
              </Text>
            </TableCell>
            <TableCell>
              <Text>
                {eligibility.validUntil
                  ? formatAsMinimizedDate(
                      eligibility.validUntil,
                      getDefaultTimezone()
                    )
                  : "No end date"}
              </Text>
            </TableCell>
            <TableCell>
              <Text>{eligibility.vehicleModel}</Text>
            </TableCell>
            <TableCell>
              <TextLink
                type="secondary"
                onClick={() => selectEligibility(eligibility)}
                dataTest={`edit-vehicle-eligibility-${eligibility.id}`}
              >
                Edit
              </TextLink>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

interface EligibilityFormProps {
  driverUid: string
  refreshOnEdit: () => void
  edit?: VehicleEligibility
  switchToDelete?: () => void
}

const EligibilityForm = ({
  edit,
  driverUid,
  refreshOnEdit,
  switchToDelete,
}: EligibilityFormProps) => {
  const [vehicleModels, setVehicleModels] = useState<VehicleModel[] | null>()
  const [loadingError, setLoadingError] = useState<EmberApiError | null>()

  useEffect(() => {
    const sub = fetchVehicleModels({
      onSuccess: setVehicleModels,
      onError: setLoadingError,
    })
    return () => sub.unsubscribe()
  }, [])

  if (loadingError) {
    return <Alert>Error loading vehicle models: {loadingError.message}</Alert>
  }
  if (!vehicleModels) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={{
        vehicleModel: edit
          ? {
              id: edit.vehicleModel,
              title: edit.vehicleModel,
            }
          : { id: vehicleModels[0].name, title: vehicleModels[0].name },
        validFrom: edit ? new Date(edit.validFrom) : startOfTomorrow(),
        validUntil:
          edit && edit.validUntil
            ? new Date(edit.validUntil)
            : addDays(startOfTomorrow(), 1),
        openEnded: edit ? !edit.validUntil : true,
      }}
      validationSchema={Yup.object({
        validFrom: Yup.date()
          .required("You must enter a valid from time")
          .typeError("You must enter a valid from time"),
        validUntil: Yup.date().endDateGreaterThanStart(
          "validFrom",
          "openEnded"
        ),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true)
        setStatus({
          success: false,
          error: null,
        })
        const { vehicleModel, validFrom, validUntil, openEnded } = values

        const body = {
          vehicleModel: vehicleModel.title,
          validFrom: zonedStartOfDay(
            validFrom,
            getDefaultTimezone()
          ).toISOString(),
          validUntil: openEnded
            ? null
            : zonedEndOfDay(validUntil, getDefaultTimezone()).toISOString(),
        }

        const opts = {
          driverUid,
          body,
          onSuccess: () => {
            refreshOnEdit()
            setStatus({
              success: true,
            })
          },
          onError: (err) => {
            setStatus({
              success: false,
              error: `Error ${
                edit ? "editing" : "adding"
              } vehicle eligibility. The error message was "${err.message}"`,
            })
            // setSubmitting(false) in onError, not onCompletion, because
            // onSuccess unmounts the form anyway and setSubmitting(false)
            // after unmounting gets us a React warning
            setSubmitting(false)
          },
          onCompletion: () => {
            // see above
          },
        }
        if (edit) {
          patchVehicleEligibility({ ...opts, eligibilityId: edit.id })
        } else {
          addVehicleEligibility(opts)
        }
      }}
    >
      {(props) => (
        <>
          <Form>
            <Stack spacing="large">
              <>
                <EmberFormSelect
                  options={vehicleModels.map((m) => ({
                    id: m.name,
                    title: m.name,
                  }))}
                  name="vehicleModel"
                  label="Vehicle Model"
                />
                <EmberDateInput
                  name="validFrom"
                  label="Valid From"
                  closeOnSelect
                />
                <FormikCheckbox name="openEnded" label="No end date" />
                {!props.values.openEnded && (
                  <EmberDateInput
                    name="validUntil"
                    label={"Valid Until"}
                    minDate={addDays(props.values.validFrom, 1)}
                    closeOnSelect
                  />
                )}
              </>
              <Stack spacing="XSmall">
                <Button
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  fullWidth={true}
                  submit={true}
                  type={"primary"}
                  dataTest="edit-route-eligibility-form-submit"
                >
                  Save
                </Button>
                {switchToDelete && (
                  <Button
                    type="criticalSubtle"
                    onClick={() => switchToDelete()}
                    fullWidth
                    dataTest="delete-route-eligibility"
                  >
                    Remove Eligibility
                  </Button>
                )}
              </Stack>
              {props.status?.error && (
                <Alert type="critical">{props.status.error}</Alert>
              )}
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  )
}

interface DeleteEligibilityProps {
  driverUid: string
  driverName: string
  eligibility: VehicleEligibility
  refreshOnEdit: () => void
}

const DeleteEligibility = ({
  driverUid,
  driverName,
  eligibility,
  refreshOnEdit,
}: DeleteEligibilityProps) => {
  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (_, { setSubmitting, setStatus }) => {
        setSubmitting(true)
        setStatus({
          success: false,
          error: null,
        })

        deleteVehicleEligibility({
          driverUid,
          eligibilityId: eligibility.id,
          onSuccess: () => {
            refreshOnEdit()
            setStatus({
              success: true,
            })
          },
          onError: (err) => {
            setStatus({
              success: false,
              error: `Error deleting vehicle eligibility. The error message was "${err.message}"`,
            })
            // setSubmitting(false) in onError, not onCompletion, because
            // onSuccess unmounts the form anyway and setSubmitting(false)
            // after unmounting gets us a React warning
            setSubmitting(false)
          },
          onCompletion: () => {
            // see above
          },
        })
      }}
    >
      {(props) => (
        <Form>
          <Stack spacing="large">
            <Text>
              Are you sure you wish to remove {driverName}'s eligibility to
              drive a {eligibility.vehicleModel}?
            </Text>
            <Stack spacing="XSmall">
              <Button
                disabled={props.isSubmitting}
                loading={props.isSubmitting}
                fullWidth={true}
                submit={true}
                type={"critical"}
              >
                Remove
              </Button>
            </Stack>
            {props.status?.error && (
              <Alert type="critical">{props.status.error}</Alert>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

const VehicleEligibilityModal = ({
  driverUid,
  driverName,
  activeVehicles,
  historicVehicles,
  handleClose,
  refreshOnEdit,
}: VehicleEligibilityModalProps) => {
  const [view, setView] = useState<
    | ["list"]
    | ["add"]
    | ["edit", VehicleEligibility]
    | ["delete", VehicleEligibility]
  >(["list"])
  const [showHistoric, setShowHistoric] = useState(false)

  return (
    <EmberModal size="small" onClose={handleClose}>
      <EmberModalHeader title="Vehicle Eligibility" />
      <EmberModalSection>
        {view[0] == "list" && (
          <Stack>
            <EligibilityTable
              eligibilities={showHistoric ? historicVehicles : activeVehicles}
              selectEligibility={(eligibility) => {
                setView(["edit", eligibility])
              }}
              type="Active"
            />

            <Stack spacing="XSmall">
              <Button fullWidth={true} onClick={() => setView(["add"])}>
                Add Eligibility
              </Button>
              {historicVehicles.length > 0 && (
                <Button
                  type="primarySubtle"
                  onClick={() => setShowHistoric(!showHistoric)}
                  fullWidth
                >
                  {showHistoric
                    ? "View Active Eligibility"
                    : "View Historic Eligibility"}
                </Button>
              )}
            </Stack>
          </Stack>
        )}

        {view[0] == "add" && (
          <EligibilityForm
            driverUid={driverUid}
            refreshOnEdit={refreshOnEdit}
            edit={null}
            switchToDelete={null}
          />
        )}
        {view[0] == "edit" && (
          <EligibilityForm
            driverUid={driverUid}
            refreshOnEdit={refreshOnEdit}
            edit={view[1]}
            switchToDelete={() => setView(["delete", view[1]])}
          />
        )}
        {view[0] == "delete" && (
          <DeleteEligibility
            driverUid={driverUid}
            driverName={driverName}
            eligibility={view[1]}
            refreshOnEdit={refreshOnEdit}
          />
        )}
      </EmberModalSection>
    </EmberModal>
  )
}

export default VehicleEligibilityModal

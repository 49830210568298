// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import styled, { css } from "styled-components"

import {
  Loading,
  Separator,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { fetchOffPatterns } from "api/off-patterns"

import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "components/generic/ember-card"
import { EmptyStateCardSection } from "components/generic/empty-state-card-section"

import { OffPattern } from "types/offPattern"

import { getPersonName } from "utils/name-utils"

import OffPatternModal from "./off-pattern-modal"

interface OffPatternRowProps {
  offPattern: OffPattern
  editItem: () => void
}

const OffPatternRow = ({ offPattern, editItem }: OffPatternRowProps) => {
  return (
    <>
      <Text dataTest={`off-pattern-${offPattern.id}-description`}>
        {offPattern.description}
      </Text>
      {
        <Stack justify="end">
          <TextLink type="secondary" onClick={editItem}>
            Edit
          </TextLink>
        </Stack>
      }
    </>
  )
}

const OffPatternsListWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 190px);
  display: grid;
  grid-template-columns:
    repeat(1, minmax(min-content, max-content))
    auto;
  column-gap: ${(props) => props.theme.orbit.spaceXXSmall};
  row-gap: ${(props) => props.theme.orbit.spaceMedium};
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  border-bottom: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  background: ${(props) => props.theme.orbit.backgroundCard};
  padding: ${(props) => props.theme.orbit.spaceSmall};
  ${media.mediumMobile(css`
    column-gap: ${(props) => props.theme.orbit.spaceSmall};
  `)}
  ${media.largeMobile(css`
    border: 1px solid ${(props) => props.theme.orbit.borderColorCard};
    padding: ${(props) => props.theme.orbit.spaceLarge};
    row-gap: ${(props) => props.theme.orbit.spaceLarge};
    column-gap: ${(props) => props.theme.orbit.spaceLarge};
  `)}
`

const SeparatorWrapper = styled.div`
  grid-column: 1 / -1;
  height: 1px;
  margin: 0px -${(props) => props.theme.orbit.spaceMedium};
  ${media.largeMobile(
    css`
      margin: 0px -${(props) => props.theme.orbit.spaceLarge};
    `
  )}
`

interface OffPatternsListProps {
  items: OffPattern[]
  openEditModal: (restrictedDate: OffPattern) => void
}

const OffPatternsList = ({ items, openEditModal }: OffPatternsListProps) => (
  <OffPatternsListWrapper>
    {items.map((item, index) => (
      <React.Fragment key={index}>
        <OffPatternRow offPattern={item} editItem={() => openEditModal(item)} />
        {index < items.length - 1 && (
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
        )}
      </React.Fragment>
    ))}
  </OffPatternsListWrapper>
)

export interface fetchOffPatternsParams {
  onSuccess: (offPatterns: OffPattern[]) => void
  onError: (errorMessage: string) => void
}

interface OffPatternsSectionProps {
  employeeUid: string
  refreshCounter?: number
  refreshFunc: () => void
}

const OffPatternsSection = ({
  employeeUid,
  refreshCounter,
  refreshFunc,
}: OffPatternsSectionProps) => {
  const [offPatterns, setOffPatterns] = useState<OffPattern[]>([])
  const [_, setError] = useState(null)
  const [ladingOffPatterns, setLadingOffPatterns] = useState(false)
  const [showOffPattenModal, setShowOffPattenModal] = useState(false)
  const [editOffPattern, setEditOffPattern] = useState<OffPattern>()

  const fetchOffPatternsOnSuccess = (offPatterns: OffPattern[]) => {
    offPatterns.forEach((element) => {
      element.employee.name = getPersonName(element.employee)
    })
    offPatterns.sort((a, b) => {
      if (a.employee.name < b.employee.name) return -1
      if (a.employee.name > b.employee.name) return 1
      return 0
    })
    setOffPatterns(offPatterns)
    setLadingOffPatterns(false)
  }

  const fetchOffPatternsOnError = (errorMessage: string) => {
    setError(errorMessage)
    setLadingOffPatterns(false)
  }

  useEffect(() => {
    setLadingOffPatterns(true)
    setOffPatterns([])
    fetchOffPatterns({
      employeeUid: employeeUid,
      onSuccess: fetchOffPatternsOnSuccess,
      onError: fetchOffPatternsOnError,
    })
  }, [refreshCounter])

  const openEditModal = (restrictedDate: OffPattern) => {
    setEditOffPattern(restrictedDate)
    setShowOffPattenModal(true)
  }

  const closeModal = () => {
    setShowOffPattenModal(false)
    setEditOffPattern(null)
  }

  return (
    <>
      {showOffPattenModal && (
        <OffPatternModal
          employeeUid={employeeUid}
          handleClose={closeModal}
          loadOffPatterns={refreshFunc}
          editOffPattern={editOffPattern}
        />
      )}
      <EmberCardGroup
        sectionTitle="Off Patterns"
        actions={[
          <TextLink
            key="Add Off Pattern"
            type="secondary"
            onClick={() => setShowOffPattenModal(true)}
          >
            Add Off Pattern
          </TextLink>,
        ]}
      >
        <EmberCard>
          {ladingOffPatterns ? (
            <EmberCardSection>
              <Loading type="boxLoader" text="Loading off patterns" />
            </EmberCardSection>
          ) : offPatterns.length > 0 ? (
            <OffPatternsList
              items={offPatterns}
              openEditModal={openEditModal}
            />
          ) : (
            <EmptyStateCardSection
              title="No off patterns found"
              omitIllustration
              actionOnclick={() => setShowOffPattenModal(true)}
            />
          )}
        </EmberCard>
      </EmberCardGroup>
    </>
  )
}

export { OffPatternsSection }

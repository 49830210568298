// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import Alert, { AlertButton } from "@kiwicom/orbit-components/lib/Alert"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import InputField from "@kiwicom/orbit-components/lib/InputField"
import Loading from "@kiwicom/orbit-components/lib/Loading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { fetchDriverDetails } from "api/drivers"
import { EmberApiError } from "api/errors"
import { searchPeople } from "api/person"

import {
  ListTile,
  MultiColumnLayout,
} from "components/generic/multi-column-layout"
import { DriversAdminLayout } from "components/layout-custom"
import DriverContractCard from "components/people/driver-contract/driver-contract-card"
import HRInformationCard from "components/people/driver-settings/hr-information-card"
import EditProfileModal from "components/people/edit-profile-modal"
import LeaveAccountsSummaryCard from "components/people/leave/leave-accounts-summary-card"
import { OffPatternsSection } from "components/people/off-patterns/off-patterns-section"
import ProfileCard from "components/people/profile-card"
import PersonCalendar from "components/person-calendar"

import { Audience } from "types/audience"
import { Unsubscribable } from "types/observable"
import { DriverDetails, Profile } from "types/person"

import { getPersonName } from "utils/name-utils"
import {
  updateQueryParam,
  updateStateFromUrl,
  useQueryString,
} from "utils/url-utils"

const Page = () => {
  const [drivers, setDrivers] = useState<Profile[]>([])
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(true)
  const [error, setError] = useState<EmberApiError>(null)
  const [selectedPersonUid, setSelectedPersonUid] = useState<string>(null)
  const [refreshCount, setRefreshCount] = useState<number>(0)
  const [filterString, setFilterString] = useState<string>("")
  const [getPeopleSubscription, setGetPeopleSubscription] =
    useState<Unsubscribable>(null)

  const { queryString } = useQueryString()
  const [loadedQueryString, setLoadedQueryString] = useState<boolean>(false)

  const handleSetDriver = (newDriverId) => {
    setSelectedPersonUid(newDriverId)
    updateQueryParam(queryString, { tag: "driver", value: newDriverId })
  }

  const handleSetFilterString = (newFilterString) => {
    setFilterString(newFilterString)
    updateQueryParam(queryString, { tag: "filter", value: newFilterString })
  }

  const urlParams = [
    {
      tag: "driver",
      setState: handleSetDriver,
    },
    {
      tag: "filter",
      setState: handleSetFilterString,
    },
  ]

  useEffect(() => {
    if (queryString && !loadedQueryString) {
      setLoadedQueryString(true)
      updateStateFromUrl(queryString, urlParams)
    }
  }, [queryString])

  useEffect(() => {
    if (getPeopleSubscription) {
      getPeopleSubscription.unsubscribe()
    }
    setLoadingDrivers(true)
    setError(null)
    const sub = searchPeople({
      request: {
        filter: filterString,
        roleNames: ["drivers", "hub-support", "maintenance"],
        limit: 500, // Arbitrarily large number to ensure all drivers are returned
      },
      onSuccess: (drivers: Profile[]) => {
        setDrivers(drivers)
        setLoadingDrivers(false)
      },
      onError: (error: EmberApiError) => {
        setError(error)
        setLoadingDrivers(false)
      },
    })
    setGetPeopleSubscription(sub)
    return () => sub.unsubscribe()
  }, [refreshCount, filterString])

  const DriverTile = ({ driver }) => {
    return (
      <ListTile
        selectable
        selected={driver.uid == selectedPersonUid}
        onClick={() => {
          handleSetDriver(driver.uid)
        }}
      >
        <Stack spacing="XSmall" dataTest="driver-tile">
          <Heading type="title3">
            {getPersonName(driver) ? getPersonName(driver) : "Unknown Name"}
          </Heading>
          <Text>{driver.email}</Text>
        </Stack>
      </ListTile>
    )
  }

  return (
    <DriversAdminLayout title="Staff" padded={false}>
      <MultiColumnLayout
        detailSelected={selectedPersonUid != null}
        optionName="drivers"
        unselectDetail={() => setSelectedPersonUid(null)}
        sidebarHeader={
          <Stack direction="row" align="end" justify="between" grow>
            <InputField
              placeholder="Search..."
              value={filterString}
              onChange={(event) => {
                const value = event.target.value as string
                handleSetFilterString(value)
              }}
            />
          </Stack>
        }
        sidebarContent={
          <>
            {loadingDrivers ? (
              <Loading type="boxLoader" text="Loading staff..." />
            ) : error ? (
              <Alert type="critical" title="Could not load staff">
                <Stack>
                  <Text>
                    Check you are logged in with the right permissions and your
                    internet connection is active. The error message was "
                    {error.message}
                    ".
                  </Text>
                  <AlertButton
                    type="criticalSubtle"
                    onClick={() => setRefreshCount(refreshCount + 1)}
                  >
                    Try again
                  </AlertButton>
                </Stack>
              </Alert>
            ) : (
              drivers.map((driver) => {
                return <DriverTile driver={driver} key={driver.uid} />
              })
            )}
          </>
        }
        detailSection={<AdminDriverDetails driverUid={selectedPersonUid} />}
      />
    </DriversAdminLayout>
  )
}

interface AdminDriverDetailsType {
  driverUid: string
}

const AdminDriverDetails = ({ driverUid }: AdminDriverDetailsType) => {
  const [driverDetails, setDriverDetails] = useState<DriverDetails>(null)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [offPatternsRefreshCounter, setOffPatternsRefreshCounter] = useState(0)
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<EmberApiError>(null)

  useEffect(() => {
    if (driverUid != null) {
      setError(null)
      setLoading(true)
      setDriverDetails(null)
      const sub = fetchDriverDetails({
        driverUid,
        onSuccess: setDriverDetails,
        onError: setError,
        onCompletion: () => setLoading(false),
      })
      return () => sub.unsubscribe()
    }
  }, [driverUid, refreshCounter])

  return (
    <>
      {!driverUid ? (
        <Alert type="info">No staff selected</Alert>
      ) : loading ? (
        <Loading type="pageLoader" text="Loading staff details..." />
      ) : error != null ? (
        <Alert type="critical" title="Error loading staff details">
          <Stack>
            <Text>{error.message}</Text>
          </Stack>
        </Alert>
      ) : (
        <Stack spacing="large">
          {showEditProfileModal && (
            <EditProfileModal
              personUid={driverUid}
              profile={driverDetails.profile}
              handleClose={() => setShowEditProfileModal(false)}
              refreshOnEdit={() => setRefreshCounter(refreshCounter + 1)}
            />
          )}
          <ProfileCard
            personUid={driverUid}
            profile={driverDetails.profile}
            showEditModal={() => setShowEditProfileModal(true)}
          />
          <DriverContractCard
            driverUid={driverUid}
            contracts={driverDetails.contracts}
            refreshOnEdit={() => setRefreshCounter(refreshCounter + 1)}
          />
          <OffPatternsSection
            refreshCounter={offPatternsRefreshCounter}
            refreshFunc={() =>
              setOffPatternsRefreshCounter(offPatternsRefreshCounter + 1)
            }
            employeeUid={driverUid}
          />
          <HRInformationCard
            driverUid={driverUid}
            driverName={driverDetails.profile.name}
            preferences={driverDetails.preferences}
            routes={driverDetails.routes}
            vehicles={driverDetails.vehicles}
            refreshOnEdit={() => setRefreshCounter(refreshCounter + 1)}
          />
          {driverDetails.leaveAccounts.length > 0 && (
            <LeaveAccountsSummaryCard
              leaveAccounts={driverDetails.leaveAccounts}
              contracts={driverDetails.contracts}
              audience="admin"
              employeeUid={driverUid}
              refreshOnEdit={() => setRefreshCounter(refreshCounter + 1)}
            />
          )}
          <PersonCalendar audience={Audience.admin} personUid={driverUid} />
        </Stack>
      )}
    </>
  )
}

export default Page

import React, { Fragment, useState } from "react"

import { addWeeks, startOfTomorrow } from "date-fns"
import { Form, Formik } from "formik"

import { Grid } from "@kiwicom/orbit-components"
import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import {
  EmberDateInput,
  EmberRadioGroup,
} from "components/generic/formik-inputs"

import { DriverPreference } from "types/person"

import { zonedStartOfDay } from "utils/date-fns-utils"
import { formatAsAbbreviatedDate, getDefaultTimezone } from "utils/date-utils"
import { asyncFetchFromAPIBase } from "utils/fetch-utils"
import { capitalizeFirstLetter } from "utils/string-utils"

interface ChangePreferenceModalProps {
  driverUid: string
  preferences: DriverPreference[]
  refreshOnEdit: () => void
  handleClose: () => void
}

const ChangePreferenceModal = ({
  driverUid,
  preferences,
  handleClose,
  refreshOnEdit,
}: ChangePreferenceModalProps) => {
  const [showHistory, setShowHistory] = useState(false)
  return (
    <EmberModal size="small" onClose={handleClose}>
      <EmberModalHeader title="Change Driver Preference" />
      <EmberModalSection>
        <Stack spacing="large">
          {!showHistory && (
            <Formik
              initialValues={{
                preference: "mixed",
                asOf: addWeeks(startOfTomorrow(), 6),
              }}
              enableReinitialize
              onSubmit={async (values, { setSubmitting, setStatus }) => {
                setSubmitting(true)
                setStatus({
                  success: false,
                  error: null,
                })
                const { preference, asOf } = values

                const response = await asyncFetchFromAPIBase({
                  path: `/v1/drivers/${driverUid}/preferences/`,
                  method: "POST",
                  authRequired: true,
                  body: {
                    preference,
                    asOf: zonedStartOfDay(
                      asOf,
                      getDefaultTimezone()
                    ).toISOString(),
                  },
                })

                if (response.error) {
                  setStatus({
                    success: false,
                    error: `
                    Error updating preferences. The error message was "${parseErrorMessage(
                      response
                    )}" `,
                  })
                } else {
                  refreshOnEdit()
                  setStatus({
                    success: true,
                  })
                }
                setSubmitting(false)
              }}
            >
              {(props) => (
                <>
                  <Form>
                    <Stack spacing="large">
                      <EmberRadioGroup
                        label="Preference"
                        name="preference"
                        options={[
                          { value: "early", label: "Early" },
                          { value: "late", label: "Late" },
                          { value: "super_late", label: "Super Late" },
                          { value: "mixed", label: "Mixed" },
                        ]}
                      />
                      <EmberDateInput
                        name="asOf"
                        label="Effective Date"
                        closeOnSelect
                      />
                      <Stack spacing="XSmall">
                        <Button
                          disabled={props.isSubmitting}
                          loading={props.isSubmitting}
                          fullWidth={true}
                          submit={true}
                          dataTest="driver-preference-submit-form"
                        >
                          Save
                        </Button>
                        <Button
                          type="primarySubtle"
                          onClick={() => setShowHistory(true)}
                          fullWidth
                          dataTest="view-driver-preference-history"
                        >
                          View History
                        </Button>
                      </Stack>
                      {props.status?.error && (
                        <Alert type="critical">{props.status.error}</Alert>
                      )}
                    </Stack>
                  </Form>
                </>
              )}
            </Formik>
          )}
          {showHistory && (
            <>
              <Grid columns="1fr 1fr 1fr" columnGap="20px" rowGap="12px">
                <Text weight="bold">Submitted At</Text>
                <Text weight="bold">Effective Date</Text>
                <Text weight="bold">Preference</Text>
                {preferences.map((preference, index) => (
                  <Fragment key={index}>
                    <Text>
                      {formatAsAbbreviatedDate(
                        preference.submittedAt,
                        getDefaultTimezone()
                      )}
                    </Text>
                    <Text>
                      {formatAsAbbreviatedDate(
                        preference.asOf,
                        getDefaultTimezone()
                      )}
                    </Text>
                    <Text dataTest="driver-preference-historic-value">
                      {capitalizeFirstLetter(preference.preference)}
                    </Text>
                  </Fragment>
                ))}
              </Grid>
              <Button
                type="primarySubtle"
                onClick={() => setShowHistory(false)}
                fullWidth
                dataTest="change-driver-preference-button"
              >
                Change Preference
              </Button>
            </>
          )}
        </Stack>
      </EmberModalSection>
    </EmberModal>
  )
}

export default ChangePreferenceModal

// @ts-strict-ignore
import React, { Fragment, useState } from "react"

import { addDays, startOfTomorrow } from "date-fns"
import { Form, Formik } from "formik"

import { Alert, Button, Grid, Stack, Text } from "@kiwicom/orbit-components"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { FormikCheckbox } from "components/generic/ember-modal/form-modal"
import {
  EmberDateInput,
  EmberFormSelect,
  EmberNumberInput,
} from "components/generic/formik-inputs"

import Yup from "logic/validation/yup-extended"

import { LocationType } from "types/location"
import { DriverContract } from "types/person"

import { zonedEndOfDay, zonedStartOfDay } from "utils/date-fns-utils"
import { formatAsMinimizedDate, getDefaultTimezone } from "utils/date-utils"
import { asyncFetchFromAPIBase } from "utils/fetch-utils"
import { useGlobalFetcher } from "utils/state-utils"

async function asyncTerminateContract(
  driverUid: string,
  contract: DriverContract,
  terminationDate: Date
) {
  return await asyncFetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/contracts/${contract.id}/`,
    method: "PATCH",
    authRequired: true,
    body: {
      validUntil: zonedEndOfDay(
        terminationDate,
        getDefaultTimezone()
      ).toISOString(),
    },
  })
}

async function asyncCreateContract(
  driverUid: string,
  validFrom: Date,
  validUntil: Date,
  openEndedContract: boolean,
  hoursPerWeek: number,
  hubId: string,
  type: string
) {
  return await asyncFetchFromAPIBase({
    path: `/v1/drivers/${driverUid}/contracts/`,
    method: "POST",
    authRequired: true,
    body: {
      validFrom: zonedStartOfDay(validFrom, getDefaultTimezone()).toISOString(),
      validUntil: openEndedContract
        ? null
        : zonedEndOfDay(validUntil, getDefaultTimezone()).toISOString(),
      hoursPerWeek,
      hubId,
      type,
    },
  })
}

interface ContractModalProps {
  driverUid: string
  contracts: DriverContract[]
  lastContract?: DriverContract
  refreshOnEdit: () => void
  handleClose: () => void
}

export const ContractModal = ({
  driverUid,
  contracts,
  lastContract,
  handleClose,
  refreshOnEdit,
}: ContractModalProps) => {
  const { data: locations } = useGlobalFetcher("locations")
  const { data: contractTypes } = useGlobalFetcher("contractTypes")
  const [selectedContract, setSelectedContract] = useState<DriverContract>(null)
  const [terminateContract, setTerminateContract] = useState(false)
  const [updateContract, setUpdateContract] = useState(false)

  let contractTypeOptions = contractTypes.map((type) => ({
    id: type.name,
    title: type.name,
  }))

  if (lastContract && contractTypeOptions.length == 0) {
    contractTypeOptions = [{ id: lastContract.type, title: lastContract.type }]
  }

  const hubs =
    locations?.length > 0
      ? locations.filter((location) => location.type === LocationType.HUB)
      : []
  const hubOptions = hubs.map((hub) => ({
    id: hub.id.toString(),
    title: hub.name,
  }))
  return (
    <EmberModal onClose={handleClose}>
      <EmberModalHeader title="Edit Contract" />
      <EmberModalSection>
        <Stack spacing="large">
          {(contracts.length == 0 || selectedContract) && (
            <Formik
              initialValues={{
                validFrom:
                  lastContract && lastContract.validUntil
                    ? new Date(lastContract.validUntil)
                    : startOfTomorrow(),
                validUntil:
                  lastContract && lastContract.validUntil
                    ? new Date(lastContract.validUntil)
                    : addDays(startOfTomorrow(), 1),
                openEndedContract: true,
                hoursPerWeek: lastContract ? lastContract.hoursPerWeek : 40,
                type: lastContract
                  ? contractTypeOptions.find((o) => o.id === lastContract.type)
                  : contractTypeOptions[0],
                hub: lastContract
                  ? hubOptions.find(
                      (o) => o.id === lastContract.hub.id.toString()
                    )
                  : hubOptions[0],
              }}
              validationSchema={Yup.object({
                validFrom: Yup.date()
                  .required("You must enter a valid from time")
                  .typeError("You must enter a valid from time"),
                validUntil: Yup.date().endDateGreaterThanStart(
                  "validFrom",
                  "openEndedContract"
                ),
              })}
              enableReinitialize
              onSubmit={async (values, { setSubmitting, setStatus }) => {
                setSubmitting(true)
                setStatus({
                  success: false,
                  error: null,
                })
                const {
                  validFrom,
                  validUntil,
                  openEndedContract,
                  hoursPerWeek,
                  hub,
                  type,
                } = values

                let response: any = null

                if (terminateContract) {
                  response = await asyncTerminateContract(
                    driverUid,
                    selectedContract,
                    validUntil
                  )
                }

                if (updateContract || contracts.length == 0) {
                  response = await asyncCreateContract(
                    driverUid,
                    validFrom,
                    validUntil,
                    openEndedContract,
                    hoursPerWeek,
                    hub.id,
                    type.id
                  )
                }

                if (response.error) {
                  setStatus({
                    success: false,
                    error: `
                      Error adding driver contract. The error message was "${parseErrorMessage(
                        response
                      )}" `,
                  })
                } else {
                  refreshOnEdit()
                  setStatus({
                    success: true,
                  })
                }
                setSubmitting(false)
              }}
            >
              {(props) => (
                <>
                  <Form>
                    <Stack spacing="large">
                      {!terminateContract && (
                        <>
                          <EmberFormSelect
                            options={contractTypeOptions}
                            name="type"
                            label="Type"
                          />
                          <EmberFormSelect
                            options={hubOptions}
                            name="hub"
                            label="Hub"
                          />
                          <EmberNumberInput
                            name="hoursPerWeek"
                            label="Hours Per Week"
                          />
                          <EmberDateInput
                            name="validFrom"
                            label="Valid From"
                            closeOnSelect
                          />
                          <FormikCheckbox
                            name="openEndedContract"
                            label="No end date"
                          />
                        </>
                      )}
                      {(terminateContract ||
                        !props.values.openEndedContract) && (
                        <EmberDateInput
                          name="validUntil"
                          label={
                            terminateContract
                              ? "Final Day of Employment"
                              : "Valid Until"
                          }
                          minDate={
                            !terminateContract &&
                            addDays(props.values.validFrom, 1)
                          }
                          closeOnSelect
                        />
                      )}
                      <Button
                        disabled={props.isSubmitting}
                        loading={props.isSubmitting}
                        fullWidth={true}
                        submit={true}
                        type={"primary"}
                        dataTest="driver-contract-modal-form-submit"
                      >
                        Save
                      </Button>
                      {props.status?.error && (
                        <Alert type="critical">{props.status.error}</Alert>
                      )}
                    </Stack>
                  </Form>
                </>
              )}
            </Formik>
          )}
          {contracts.length > 0 && !selectedContract && (
            <Grid
              columns="repeat(5, max-content)"
              columnGap="18px"
              rowGap="12px"
            >
              <Text weight="bold">Valid From</Text>
              <Text weight="bold">Valid Until</Text>
              <Text weight="bold">Hours</Text>
              <Text weight="bold">Type</Text>
              <Text weight="bold">Hub</Text>
              {contracts.map((contract, index) => (
                <Fragment key={index}>
                  <Stack align="center">
                    <Text>
                      {formatAsMinimizedDate(
                        contract.validFrom,
                        getDefaultTimezone()
                      )}
                    </Text>
                  </Stack>
                  <Stack align="center">
                    <Text>
                      {contract.validUntil
                        ? formatAsMinimizedDate(
                            contract.validUntil,
                            getDefaultTimezone()
                          )
                        : "No end date"}
                    </Text>
                  </Stack>
                  <Stack align="center">
                    <Text>{contract.hoursPerWeek}</Text>
                  </Stack>
                  <Stack align="center">
                    <Text>{contract.type}</Text>
                  </Stack>
                  <Stack align="center">
                    <Text>{contract.hub.name}</Text>
                  </Stack>
                </Fragment>
              ))}
            </Grid>
          )}
          {contracts.length > 0 && !selectedContract && (
            <Stack spacing="XSmall">
              <Button
                onClick={() => {
                  setSelectedContract(lastContract)
                  setTerminateContract(true)
                }}
                type="criticalSubtle"
                fullWidth
                dataTest="terminate-driver-contract"
              >
                Terminate
              </Button>
              <Button
                onClick={() => {
                  setSelectedContract(lastContract)
                  setUpdateContract(true)
                }}
                type="primarySubtle"
                fullWidth
                dataTest="update-driver-contract"
              >
                Update
              </Button>
            </Stack>
          )}
        </Stack>
      </EmberModalSection>
    </EmberModal>
  )
}
